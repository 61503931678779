import enLang from './en'
import trLang from './tr'
import nlLang from './nl'

export function en() {
  return enLang
}
export function tr() {
  return trLang
}
export function nl() {
  return nlLang
}

const langList = [
  { key: 'en', label: 'English' },
  { key: 'tr', label: 'Turkish' },
  { key: 'nl', label: 'Nederlands' }
]
export default langList
