import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

import { InternationalizationProvider } from 'react-internationalization'
import * as languages from './translations'

const lang = localStorage.getItem('language') || 'en'

// window.jQuery = window.$ = require('jquery/dist/jquery.min.js');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// registerServiceWorker()

//Only do if Android - iOS is all sorts of crazy
// if (navigator.userAgent.indexOf('Android') >= 0) {
registerServiceWorker()
// ;(function askForPWAsInstallation() {
//   // let deferredPrompt
//   // let btnAdd = document.getElementById('pwas_button')

//   var promptEvent

//   window.addEventListener('beforeinstallprompt', e => {
//     console.log('beforeinstallprompt listener')
//     // e.preventDefault()
//     // deferredPrompt = e
//     // e.prompt().catch(function(err) {
//     //   // do something
//     //   console.log(JSON.stringify(err))
//     // }) // Throws if called more than once or default not prevented

//     e.preventDefault();
//     promptEvent = e;
//     listenToUserAction();

//     // btnAdd.style.display = 'block'
//   })

//   // listen to install button clic
//   function listenToUserAction() {
//     const installBtn = document.querySelector(".install-btn");
//     installBtn.addEventListener("click", presentAddToHome);
//   }

//   // present install prompt to user
//   function presentAddToHome() {
//       promptEvent.prompt();  // Wait for the user to respond to the prompt
//       promptEvent.userChoice
//         .then(choice => {
//             if (choice.outcome === 'accepted') {
//                 console.log('User accepted');
//             } else {
//                 console.log('User dismissed');
//             }
//         })
//   }

//   window.addEventListener('appinstalled', evt => {
//     console.log('a2hs', 'installed')
//   })
// })()
// }

ReactDOM.render(
  <InternationalizationProvider defaultLanguage={lang} languages={languages}>
    <App />
  </InternationalizationProvider>,
  document.getElementById('root')
)
