import 'whatwg-fetch'; 
export default class Util {
  static getDeviceUuid(result) {
    const SHARED_DATA_ENDPOINT = '/uuid'
    return fetch(SHARED_DATA_ENDPOINT)
      .then(response => response.json())
      .then(data => {
        if (process.env.REACT_APP_PROD !== 'true') console.log('Got', data, 'from cache')
        if (data && data.uuid) {
          localStorage.setItem('StoredDeviceUuid', data.uuid)
          return data.uuid
        } else {
          const storedDeviceUuid = localStorage.getItem('StoredDeviceUuid')
          if (storedDeviceUuid && storedDeviceUuid !== '') {
            result = storedDeviceUuid
          } else {
            localStorage.setItem('StoredDeviceUuid', result)
            fetch(SHARED_DATA_ENDPOINT, {
              method: 'POST',
              body: JSON.stringify({ uuid: storedDeviceUuid })
            }).then(res => {
              if (process.env.REACT_APP_PROD !== 'true') console.log('saved to cache')
            })
          }

          return result
        }
      })
      .catch(err => {
        console.log(err)
        const storedDeviceUuid = localStorage.getItem('StoredDeviceUuid')
        if (storedDeviceUuid && storedDeviceUuid !== '') {
          result = storedDeviceUuid
        } else {
          localStorage.setItem('StoredDeviceUuid', result)
          fetch(SHARED_DATA_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify({ uuid: storedDeviceUuid })
          }).then(res => {
            console.log('saved to cache')
          })
        }

        return result
      })
  }
}
