const nlLang = {
  check_network_connection: 'Check network connection',
  no_scheme_found_for: 'No loyalty scheme found for {merchant}',
  failed_to_read_loyalty_scheme: 'Failed to read loyalty scheme',
  invalid_stamp: 'Invalid stamp',
  server_error: 'Server error: {error}',
  stamp_not_valid: 'Stamp not valid',
  loyalty_card_not_valid: 'Loyalty card not valid',
  card_updated: 'Card updated',
  reward_redeemed: 'Reward redeemed',
  could_not_create_new_card: 'Could not create new card',
  stamp_successful_collected: 'Stamp successful! {stamps} stamps collected',
  stamp_not_recognised: 'Stamp not recognised',
  update_card_error: 'Update card error: {error}',
  new_customer: 'New customer',
  show_at_counter: 'Show this at the counter',
  audio_error: 'Error playing sound',
  local_storage_disabled: 'You are in Privacy Mode or local storage is disabled\nPlease deactivate Privacy Mode and then reload the page.',
  local_storage_unavailable: 'You are in Privacy Mode or local storage is unavailable\nPlease deactivate Privacy Mode and then reload the page.',
  ensure_not_private: 'Please ensure you are not browsing in Privacy Mode\nIf in private mode, points will NOT be saved.\nPlease deactivate Privacy Mode and then reload the page if it is enabled.',
  scheme_disabled: 'Buzzoek is opgeschort voor dit bedrijf',
  card_valid_from: 'Geldig vanaf',
  card_valid_to: 'Geldig tot',
  loyalty_card_expired: 'Loyalty card has expired'
}

export default nlLang
