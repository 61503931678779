import React, { Component } from 'react'
import './Stampcard.css'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import { AppInstances } from '../utils/buzzoekSdkInstance'
import 'whatwg-fetch'
import { setLanguage, translate, Text } from 'react-internationalization'
const SHARED_STAMP_ENDPOINT = '/no_stamps'

class Stampcard extends Component {
  // Adds a class constructor that assigns the initial state values:
  constructor(props) {
    super(props)
    this.state = {
      no_stamps: '',
      scheme: this.props.this_scheme,
      currentLoyaltyCard: {},
    }
    this.expiryClicked = this.expiryClicked.bind(this)
    this.clearCardAndRecreate = this.clearCardAndRecreate.bind(this)
  }
  ActivateStamps(amount) {
    // window.$('.active').removeClass('active')
    // let loop = 1
    if (amount >= 0) {
      for (let x = 0; x < amount; x++) {
        var imgObj = document.getElementById('stampimg_' + x)
        window.$(imgObj).removeClass('inactive')
        window.$(imgObj).addClass('active')
        imgObj.src = this.state.scheme.stampicon
        // if (process.env.REACT_APP_PROD !== 'true') {
        //   console.log('Set stampimg_' + x + ' active')
        // }
      }
      for (let y = amount; y < (this.state.scheme.num_to_save || 10); y++) {
        imgObj = document.getElementById('stampimg_' + y)
        window.$(imgObj).removeClass('active')
        window.$(imgObj).addClass('inactive')
        imgObj.src = this.state.scheme.stampicon.replace(
          '/image/upload/',
          '/image/upload/e_grayscale/'
        )
        // if (process.env.REACT_APP_PROD !== 'true') {
        //   console.log('Set stampimg_' + y + ' inactive')
        // }
      }
    }
    localStorage.setItem('no_stamps', amount || 0)
    //Only write stamps to cache if there are more than 0 locally - otherwise overwrites existing cache value
    if ((amount || 0) > 0) {
      fetch(SHARED_STAMP_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({ stamps: amount || 0 }),
      })
        .then((res) => {
          console.log('stamps saved to cache')
        })
        .catch((err) => {
          console.log('Err writing to cache', err)
        })
    }

    if (amount >= this.state.scheme.num_to_save) {
      window.$('#fullModal').modal('show')
      //Dynamically set canvas zIndex to 9999
      var canvas = document.getElementById('canvas')
      canvas.classList.add('z-index-9999')
      // window.confirm('Do the thing?')
      // window.$('#fullModal').click(function() {
      //   console.log('Modal clicked')
      //   window.$('#fullModal').modal('hide')
      // })
      // setTimeout(function() {
      //   window.$('.active').removeClass('active')
      // }, 1000)
    }
  }

  InitStamps() {
    let _this = this
    setTimeout(function() {
      _this.ActivateStamps(parseInt(localStorage.getItem('no_stamps')))
    }, 0)
  }

  async requestNotificationPermission() {
    const permission = await window.Notification.requestPermission()
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission !== 'granted') {
      //TODO log state user chose, to the server
      console.log('Notification permission denied')
      // ToastsStore.error('Notification permission denied')
      // throw new Error('Permission not granted for Notification')
    }
  }

  async clearCardAndRecreate({complete=true, redeemed=true, expired=false, activity={}}) {
    let vm = this
    const buzzoek = await AppInstances.getBuzzoekSdk()
    let amountOfStamps = localStorage.getItem('no_stamps')
    await buzzoek.loyaltyCards
      .update(vm.state.currentLoyaltyCard._id, {
        complete,
        redeemed,
        expired,
        $push: {
          activity,
        },
      })
      .then(function(response) {
        if (process.env.REACT_APP_PROD !== 'true') ToastsStore.info(translate('card_updated'))
        return
      })
      .catch(function(err) {
        ToastsStore.error(translate('update_card_error').replace('{error}', JSON.stringify(err)))
      })

    amountOfStamps = Math.max(amountOfStamps - this.state.scheme.num_to_save, 0)

    
    //Ensure cache reset to 0 if reward redeemed / card expired
    if (amountOfStamps === 0) {
      fetch(SHARED_STAMP_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({ stamps: 0 }),
      })
        .then((res) => {
          console.log('stamps saved to cache')
        })
        .catch((err) => {
          console.log('Err writing to cache', err)
        })
    }

    this.ActivateStamps(amountOfStamps)

    let cardBody = {
      merchant: this.state.scheme.merchant,
      scheme: this.state.scheme._id,
      store: this.state.scheme.store,
      used: amountOfStamps,
      stampable: this.state.scheme.num_to_save,
      free: this.state.scheme.num_free,
      redeemed: false,
      complete: false,
      expired: false
    }

    await buzzoek.loyaltyCards
      .create(cardBody)
      .then(function(response) {
        vm.setState({ currentLoyaltyCard: response })
      })
      .catch(function(err) {
        ToastsStore.error(translate('could_not_create_new_card'))
        console.log(err)
      })
  }

  async expiryClicked() {
    //On click, set card as redeemed:false, complete:true, expired:true and add action to card indicating expired
    await this.clearCardAndRecreate({
      complete: true,
      redeemed: false,
      expired: true,
      activity: {
        date: new Date(),
        type: 'Expiry',
      },
    })
    
    window.$('#expiryModal').modal('hide')
  }

  async StampFound(retrieved_device) {
    if (!(retrieved_device && retrieved_device.scheme && retrieved_device.scheme._id)) {
      ToastsStore.error(translate('invalid_stamp'))
      return
    }

    const buzzoek = await AppInstances.getBuzzoekSdk()

    const loyalty_scheme = retrieved_device.scheme._id

    const searchBody = {
      'filter[merchant]': retrieved_device.merchant,
      'operator[merchant]': '$eq',
      'filter[scheme]': loyalty_scheme,
      'operator[scheme]': '$eq',
      'filter[redeemed]': false,
      'operator[redeemed]': '$eq',
      'filter[complete]': false,
      'operator[complete]': '$eq',
      'filter[expired]': false,
      'operator[expired]': '$eq'
    }

    return buzzoek.loyaltyCards
      .read(searchBody)
      .then((resp) => {
        if (process.env.REACT_APP_PROD !== 'true') console.log('### Card response', (resp || [])[0])
        return (resp || [])[0] || {}
      })
      .catch((error) => {
        console.log('Retrieve card error: ' + JSON.stringify(error))
        return null
      })
  }
  // This is called when an instance of a component is being created and inserted into the DOM.
  async componentDidMount() {
    if (!navigator.onLine) {
      ToastsStore.error(translate('check_network_connection'))
      this.setState({ no_stamps: localStorage.getItem('no_stamps') || 0 })
    }

    //check current version # and update if necessary
    if (localStorage.getItem('current_version') !== process.env.REACT_APP_VERSION) {
      localStorage.setItem('current_version', process.env.REACT_APP_VERSION)
      if (process.env.REACT_APP_PROD !== 'true') {
        console.log('### Reloading')
      }
      window.location.reload() //Refresh cache in case new version on server
    }

    // console.log("MERCHANT: " + ((window.location.hostname||"").split('.')||[])[0])
    //use merchant identifier to retrieve card & title details
    const org = ((window.location.hostname || '').split('.') || [])[0]

    const lang = this.state.scheme.lang || 'en'
    localStorage.setItem('language', lang)
    setLanguage(lang)

    document.title =
      this.state.scheme.title ||
      org.charAt(0).toUpperCase() + org.substr(1).toLowerCase() + ' LoyaltyCard'

    document.body.style.backgroundColor = this.state.scheme.extras.background_color || '#ffffff'

    //Dynamic manifest
    var dynamicManifest = {
      short_name:
        this.state.scheme.title || org.charAt(0).toUpperCase() + org.substr(1).toLowerCase(),
      name:
        this.state.scheme.title ||
        org.charAt(0).toUpperCase() + org.substr(1).toLowerCase() + ' LoyaltyCard',
      icons: [
        {
          src: this.state.scheme.logo,
          sizes: '512x512 192x192',
          type: 'image/png',
        },
      ],
      start_url: window.location.href + '',
      display: 'standalone',
      theme_color: '#000000',
      background_color: '#ffffff',
    }

    const stringManifest = JSON.stringify(dynamicManifest)
    const blob = new Blob([stringManifest], { type: 'application/json' })
    const manifestURL = URL.createObjectURL(blob)
    document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL)
    document.querySelector('#shortcut-placeholder').setAttribute('href', this.state.scheme.favicon)

    let vm = this

    //FIXME: Currently only look up existing points for Android - only Android has a proper unique UUID
    // if (navigator.userAgent.indexOf('Android') >= 0) {
    var currentLoyaltyCard = await vm.StampFound({
      merchant: this.state.scheme.merchant,
      scheme: this.state.scheme,
    })

    vm.setState({ currentLoyaltyCard })

    if (
      vm.state.currentLoyaltyCard && vm.state.currentLoyaltyCard.created_at
      //&& this.state.currentLoyaltyCard.used >= (localStorage.getItem('no_stamps') || 0)
    ) {
      localStorage.setItem('no_stamps', vm.state.currentLoyaltyCard.used || 0)

      // Make sure cache has the correct number of stamps
      fetch(SHARED_STAMP_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({ stamps: vm.state.currentLoyaltyCard.used || 0 }),
      })
        .then((res) => {
          console.log('stamps saved to cache')
        })
        .catch((err) => {
          console.log('Err writing to cache', err)
        })

      // IF SCHEME HAS EXPIRY DATE PER CARD then a) check if card expired (and show toast if so, while also marking card as completed) and b) show expiry date on card
      if (vm.state.scheme.card_expiry_months) {
        var created_date = new Date(this.state.currentLoyaltyCard.created_at)
        var expiry_date = new Date(
          created_date.setMonth(
            created_date.getMonth() + parseInt(this.state.scheme.card_expiry_months)
          )
        )
        if (expiry_date.getTime() < new Date().getTime()) {
          ToastsStore.error(translate('loyalty_card_expired'), 30000)
          // Show popup indicating expired card
          window.$('#expiryModal').modal('show')
        }
      }
    }
    // else create one
    else {
      const buzzoek = await AppInstances.getBuzzoekSdk()

      const cardBody = {
        merchant: this.state.scheme.merchant,
        scheme: this.state.scheme._id,
        used: 0,
        stampable: this.state.scheme.num_to_save,
        free: this.state.scheme.num_free,
        redeemed: false,
        complete: false,
        expired: false
      }

      await buzzoek.loyaltyCards
        .create(cardBody)
        .then(function(response) {
          // this.state.currentLoyaltyCard = response
          localStorage.setItem('no_stamps', 0)
          vm.setState({ currentLoyaltyCard: response })
        })
        .catch(function(err) {
          ToastsStore.error(translate('could_not_create_new_card'))
        })
    }

    // }

    this.InitStamps()

    const key = '5zAE63V7BDzswN5XabTwDm'
    // process.env.REACT_APP_PROD === 'true' ? '5zAE63V7BDzswN5XabTwDm' : '23716c6d92654d59ac3a06'
    const secret = '26kBze5fe8AVXKWwzzD93t8UEu8dSd92NnbwsTHMEhc3'
    // process.env.REACT_APP_PROD === 'true'
    //   ? '26kBze5fe8AVXKWwzzD93t8UEu8dSd92NnbwsTHMEhc3'
    //   : '69ab701a2f6d114ff2854764372e80145f62b0c74fc4'

    var self = this

    window.tictag.init(
      'canvas',
      // '5zAE63V7BDzswN5XabTwDm',
      // '26kBze5fe8AVXKWwzzD93t8UEu8dSd92NnbwsTHMEhc3',
      key,
      secret,
      {
        debug: process.env.REACT_APP_PROD === 'true' ? false : true,
        // settings: {
        timesToStamp: 3,
        timesToStampAndroid: 5,
        delayTimeIos: 80,
        delayTimeAndroid: 80,
        // resetTimeoutNostamp: 100,
        // resetTimeoutNostampIos: 100,
        resetTimeout: 200,
        fullReset: true,
        connectionTimeout: 1000,
        endpoint:
          process.env.REACT_APP_PROD === 'true'
            ? 'https://tictag.loyaltycard.app/v1/stamp/verify'
            : 'https://tictag-staging.loyaltycard.app/v1/stamp/verify',
        // vibrate: true,
        // },
        callback: {
          found: async function(stamp) {
            // console.log("Add Stamp");
            let amountOfStamps = localStorage.getItem('no_stamps')
            if (
              ((window.location.hostname || '').split('.') || [])[0].toLowerCase() === 'test' ||
              window.location.hostname === 'localhost' ||
              window.location.hostname.match(
                /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
              )
            ) {
              stamp.visualId = '1111'
            }

            //If amountOfStamps is greater than max already, then redeem reward and reduce number of stamps
            if (amountOfStamps >= self.state.scheme.num_to_save) {
              const buzzoek = await AppInstances.getBuzzoekSdk()
              const stampDevice = await buzzoek.devices.readOne
                .stamp(parseFloat(stamp.visualId).toFixed(0), { scheme: vm.state.scheme._id })
                .then((result) => {
                  return result
                })
                .catch((err) => {
                  if (process.env.REACT_APP_PROD !== 'true') {
                    console.log(err)
                    ToastsStore.error(
                      translate('server_error').replace('{error}', JSON.stringify(err)),
                      7000
                    )
                  }
                })

              if (!stampDevice) {
                if (process.env.REACT_APP_PROD === 'true')
                  ToastsStore.error(translate('stamp_not_valid'))
                else ToastsStore.error('Stamp ' + stamp.visualId + ' not valid')
                return
              }

              //Only check current card for Android
              // if (navigator.userAgent.indexOf('Android') >= 0) {
              var currentLoyaltyCard = await vm.StampFound(stampDevice)
              vm.setState({ currentLoyaltyCard })

              if (!(vm.state.currentLoyaltyCard && vm.state.currentLoyaltyCard.created_at)) {
                ToastsStore.error(translate('loyalty_card_not_valid'))
                amountOfStamps = Math.max(amountOfStamps - self.state.scheme.num_to_save, 0)
                vm.ActivateStamps(amountOfStamps)
                return
              }

              await vm.clearCardAndRecreate({
                complete: true,
                redeemed: true,
                expired: false,
                activity: {
                  stamp: stampDevice._id,
                  store: stampDevice.store,
                  date: new Date(),
                  type: 'Reward',
                },
              })

              window.$('#fullModal').modal('hide')
              if (
                vm.state.scheme.extras.sound_enabled &&
                navigator.userAgent.indexOf('Android') >= 0
              ) {
                let audio = new Audio('/sound/reward.wav')
                audio.play()
              }

              //Dynamically set canvas zIndex back to 1
              var canvas = document.getElementById('canvas')
              canvas.classList.add('z-index-1')

              ToastsStore.success(translate('reward_redeemed'), 5000)

            } else {
              amountOfStamps++
              window.$('.logo').addClass('logo-scale')
              if (
                vm.state.scheme.extras.sound_enabled &&
                navigator.userAgent.indexOf('Android') >= 0
              ) {
                let audio = new Audio('/sound/stamp.mp3')
                audio.play()
              }

              setTimeout(function() {
                window.$('.logo').removeClass('logo-scale')
              }, 500)

              const buzzoek = await AppInstances.getBuzzoekSdk()
              const stampDevice = await buzzoek.devices.readOne
                .stamp(parseFloat(stamp.visualId).toFixed(0), { scheme: vm.state.scheme._id })
                .then((result) => {
                  return result
                })
                .catch((err) => {
                  if (process.env.REACT_APP_PROD !== 'true') {
                    console.log(err)
                    ToastsStore.error(
                      translate('server_error').replace('{error}', JSON.stringify(err)),
                      7000
                    )
                  }
                })

              if (!stampDevice) {
                if (process.env.REACT_APP_PROD === 'true')
                  ToastsStore.error(translate('stamp_not_valid'))
                else ToastsStore.error('Stamp ' + stamp.visualId + ' not valid')
                return
              }

              currentLoyaltyCard = await vm.StampFound(stampDevice)
              vm.setState({ currentLoyaltyCard })

              if (!(vm.state.currentLoyaltyCard && vm.state.currentLoyaltyCard.created_at)) {
                //Create card
                let cardBody = {
                  merchant: stampDevice.merchant,
                  scheme: stampDevice.scheme._id,
                  used: amountOfStamps,
                  stampable: stampDevice.scheme.num_to_save,
                  free: stampDevice.scheme.num_free,
                  redeemed: false,
                  complete: false,
                  expired: false,
                  store: stampDevice.store,
                  activity: [
                    {
                      stamp: stampDevice._id,
                      store: stampDevice.store,
                      date: new Date(),
                      type: 'Stamp',
                    },
                  ],
                }

                buzzoek.loyaltyCards
                  .create(cardBody)
                  .then(function(response) {
                    //create in collection then createCardPopup(loyaltyCard);
                    // response.scheme = stampDevice.scheme; // Expand out scheme
                  })
                  .catch(function(err) {
                    ToastsStore.error(translate('could_not_create_new_card'))
                  })
              } else {
                // amountOfStamps = this.state.currentLoyaltyCard.used++
                await buzzoek.loyaltyCards
                  .update(vm.state.currentLoyaltyCard._id, {
                    used: amountOfStamps,
                    $push: {
                      activity: {
                        stamp: stampDevice._id,
                        store: stampDevice.store,
                        date: new Date(),
                        type: 'Stamp',
                      },
                    },
                  })
                  .then(function(response) {
                    return
                  })
                  .catch(function(err) {
                    console.error('### Update card error: ' + JSON.stringify(err))
                  })
              }

              ToastsStore.success(
                translate('stamp_successful_collected').replace('{stamps}', amountOfStamps),
                5000
              )
              vm.ActivateStamps(amountOfStamps)
            }
          },
          start: function() {
            // console.log("START")
          },
          reset: function() {
            // console.log("RESET")
          },
          noFound: function() {
            console.log('NOT A STAMP')
            // ToastsStore.error('Not a stamp!');
          },
          notFound: function() {
            // console.log("NOT FOUND")
            if (!navigator.onLine) {
              ToastsStore.error(translate('check_network_connection'))
            } else {
              ToastsStore.error(translate('stamp_not_recognised'))
            }
          },
          sending: function() {
            // console.log("SENDING")
          },
        },
      }
    )

    document.addEventListener('gesturestart', function(e) {
      e.preventDefault()
    })

    if ('PushManager' in window) {
      console.log('Push Manager available')
      // TODO await vm.requestNotificationPermission()
    } else {
      console.log('No push manager available')
    }

    if (process.env.REACT_APP_PROD !== 'true') {
      console.log('### UUID', localStorage.getItem('StoredDeviceUuid'))
      // ToastsStore.info('uuid ' + localStorage.getItem('StoredDeviceUuid'), 7000)
    }

    const storedStamps = localStorage.getItem('no_stamps')

    fetch(SHARED_STAMP_ENDPOINT)
      .then((response) => response.json())
      .then((data) => {
        if (process.env.REACT_APP_PROD !== 'true') {
          console.log('Stamps in cache ' + (data || {}).stamps + ' vs ' + storedStamps + ' locally')
          // ToastsStore.info(
          //   'Stamps in cache ' + (data || {}).stamps + ' vs ' + storedStamps + ' locally',
          //   1000
          // )
        }
        if (data && data.stamps && data.stamps > storedStamps) {
          localStorage.setItem('no_stamps', data.stamps)
          this.InitStamps()
        }
      })
      .catch((err) => {
        if (process.env.REACT_APP_PROD !== 'true') {
          console.log('Error retrieving stamps ' + JSON.stringify(err))
          // ToastsStore.info('Local stamps ' + storedStamps, 1000)
        }
      })
  }
  // The render method contains the JSX code which will be compiled to HTML.
  render() {
    const itemsSet = []
    const num_cols = this.state.scheme.extras.layout.columns || this.state.scheme.num_to_save / 2
    const num_rows = this.state.scheme.extras.layout.rows || 2
    const max_display = this.state.scheme.num_to_save || 10

    const points_bkg_color = this.state.scheme.extras.background_color_points || '#f6f2ea'

    var total_laid_out = 0

    for (var j = 0; j < num_rows; j++) {
      let keyCounter = 0
      const items = []
      // items.push(<div className="col-xs-1" key={keyCounter + 'j' + j} />)
      // keyCounter++

      for (var i = 0; i < num_cols; i++) {
        if (total_laid_out < max_display) {
          items.push(
            <div className="col-xs-2" key={i}>
              <img
                src={this.state.scheme.stampicon.replace(
                  '/image/upload/',
                  '/image/upload/e_grayscale/'
                )}
                id={'stampimg_' + total_laid_out}
                alt="Stamp"
              />
            </div>
          )
          total_laid_out++
        }
      }
      // items.push(<div className="col-xs-1" key={keyCounter + 'j' + j} />)
      // keyCounter++
      itemsSet.push(
        <div className=" container space-around" key={keyCounter + 'jx' + j}>
          <div className="row zegels space-around" key={keyCounter + 'j' + j}>
            {items}
          </div>
        </div>
      )
      keyCounter++
      if (j <= num_rows - 1) {
        // for (var k = 0; k < 2; k++) {
        itemsSet.push(<br key={keyCounter + 'j' + j} />)
        keyCounter++
        // }
      }
    }

    // Function to parse the text and convert the hyperlink into an anchor tag
    const renderTextWithLink = (textWithLink) => {
      const parts = textWithLink.split(' ')

      return parts.map((part, index) => {
        if (part.startsWith('https://') || part.startsWith('http://')) {
          // If the part is a hyperlink, render it as a clickable link
          return (
            <div className="navbar-item">
              <a key={index} href={part} target="_blank" rel="noopener noreferrer">
                {part}
              </a>
            </div>
          )
        } else {
          // Otherwise, render normal text
          return <span key={index}>{part} </span>
        }
      })
    }

    const disclaimer_note = this.state.scheme.disclaimer_text ? (
      <div
        className="sm"
        style={{ fontFamily: this.state.scheme.extras.disclaimer_font || "'Lato', sans-serif" }}
      >
        <span style={{ whiteSpace: 'pre-line' }}>
          {renderTextWithLink(this.state.scheme.disclaimer_text.split('<br/>').join('\n'))}
        </span>
      </div>
    ) : null

    const redeem_text = this.state.scheme.extras.display_redeem_text ? (
      <span>
        <Text id="show_at_counter" />
      </span>
    ) : null

    var date_options = { year: 'numeric', month: 'long', day: 'numeric' }
    var created_date = new Date((this.state.currentLoyaltyCard||{}).created_at)

    const expiration_info =
      this.state.scheme.card_expiry_months && (this.state.currentLoyaltyCard || {}).created_at ? (
        <div
          className="sm text-center"
          style={{ fontFamily: this.state.scheme.extras.disclaimer_font || "'Lato', sans-serif" }}
        >
          <span style={{ whiteSpace: 'pre-line' }}>
            <Text id="card_valid_to" />
            <br />
            <span>
              {new Date(
                created_date.setMonth(
                  created_date.getMonth() + parseInt(this.state.scheme.card_expiry_months)
                )
              ).toLocaleDateString('nl-NL', date_options)}
            </span>
            {/* <Text id="card_valid_to" />
            {new Date(this.state.currentLoyaltyCard.created_at).toISOString()} */}
          </span>
        </div>
      ) : null

    return (
      <div className="current--card container">
        <div className="container">
          <link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet" />
          <link
            rel="stylesheet"
            href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
            crossOrigin="anonymous"
          />
          <br />
          <div className="text-center image-pad-top">
            <img className="logo" src={this.state.scheme.logo} alt="logo" />
            {/* <h1>LOYALTY POINTS</h1> */}
          </div>
          <div
            id="points_container"
            className="points-container"
            style={{ backgroundColor: points_bkg_color }}
          >
            {itemsSet}
          </div>
          <br />
          <div
            className="text-center"
            // style={{ fontFamily: this.state.scheme.extras.reward_font ? `'${this.state.scheme.extras.reward_font}' !important` : "'Lato', sans-serif" }}
            style={{ fontFamily: this.state.scheme.extras.reward_font || "'Lato', sans-serif" }}
            // style={{ fontFamily: 'causten-regular' }}
          >
            <h6>
              <span>{this.state.scheme.reward_description}</span>
            </h6>
            {disclaimer_note}
          </div>
          {expiration_info}
          <br />
        </div>
        <div
          className="modal fade"
          id="fullModal"
          tabIndex="-1"
          role="dialog"
          data-backdrop="static"
          data-keyboard="false"
          z-index="9999"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-body">
              <div className="text-center">
                <img src={this.state.scheme.rewardicon} alt="Reward" />
              </div>
              <div className="text-center centered">{redeem_text}</div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="expiryModal"
          tabIndex="-1"
          role="dialog"
          data-backdrop="static"
          data-keyboard="false"
          z-index="9999"
          onClick={this.expiryClicked}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-body">
              <div className="text-center">
                <img src={this.state.scheme.expiryicon} alt="Expired" />
              </div>
              <div className="text-center centered">{redeem_text}</div>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore} />
      </div>
    )
  }
}

export default Stampcard
