// import { CLIENT_ID, CLIENT_SECRET, API_SERVER } from 'react-native-dotenv';
// import { localStorage } from 'react-native';
import BuzzoekClient from '@buzzoek/sdk'

/**
 * Creating a instance for Buzzoek SDK
 */
export const AppInstances = (function() {
  var instance

  async function createBuzzoekSdk() {
    // console.log('****** CREATED COUNTR SDK ******')
    const buzzoekClient = new BuzzoekClient({
      opts: {
        staging: process.env.REACT_APP_PROD === 'true' ? false : true,
        local: false,
        enableSocket: false
      }
    })

    buzzoekClient.setClient({
      client_id:
        process.env.REACT_APP_PROD === 'true'
          ? 'client-befcc33c46fa686e112b5ab1b58221f445ba08c9'
          : 'client-18357cddf8ab3b95947385e59131a52ea7672f8e',
      client_secret:
        process.env.REACT_APP_PROD === 'true'
          ? 'secret-d72b29dbfa8b672cb2a48d6a64bef91b59bfe314'
          : 'secret-33cb7a9a22e466038ee19f00449832b7df954821'
    })

    buzzoekClient.on('refreshed', async token => {
      // console.log('****** REFRESHED TOKEN ******', token)
      if (!process.env.REACT_APP_PROD !== 'true') console.log('Refreshed token')
      try {
        localStorage.setItem('access_token', token.access_token)
        localStorage.setItem('refresh_token', token.refresh_token)
      } catch (error) {
        console.error(error)
      }
    })

    return buzzoekClient
  }

  return {
    getBuzzoekSdk: async function() {
      if (!instance) {
        // console.log('****** CREATING NEW INSTANCE FOR SDK ******')
        instance = await createBuzzoekSdk()
      }
      return instance
    }
  }
})()
