const trLang = {
  check_network_connection: 'Lütfen bağlantınızı kontrol edin',
  no_scheme_found_for: 'Lütfen az sonra tekrar deneyin. Hata kodu: 010',
  failed_to_read_loyalty_scheme: 'Lütfen az sonra tekrar deneyin. Hata kodu: 011',
  invalid_stamp: 'Lütfen az sonra tekrar deneyin. Hata kodu: 012',
  server_error: 'Lütfen az sonra tekrar deneyin. Hata kodu: 013',
  stamp_not_valid: 'Lütfen az sonra tekrar deneyin. Hata kodu: 014',
  loyalty_card_not_valid: 'Lütfen az sonra tekrar deneyin. Hata kodu: 015',
  card_updated: 'Kart güncellendi',
  reward_redeemed: 'Ödül kullanıldı',
  could_not_create_new_card: 'Lütfen az sonra tekrar deneyin. Hata kodu: 016',
  stamp_successful_collected: 'Başarılı damga. Toplam damga: {stamps}',
  stamp_not_recognised: 'Lütfen az sonra tekrar deneyin. Hata kodu: 017',
  update_card_error: 'Lütfen az sonra tekrar deneyin. Hata kodu: 018',
  new_customer: 'Yeni müşteri',
  show_at_counter: 'Lütfen bunu kasada gösterin',
  audio_error: 'Error playing sound',
  local_storage_disabled: 'You are in Privacy Mode or local storage is disabled\nPlease deactivate Privacy Mode and then reload the page.',
  local_storage_unavailable: 'You are in Privacy Mode or local storage is unavailable\nPlease deactivate Privacy Mode and then reload the page.',
  ensure_not_private: 'Please ensure you are not browsing in Privacy Mode\nIf in private mode, points will NOT be saved.\nPlease deactivate Privacy Mode and then reload the page if it is enabled.'
}

export default trLang
