import React, { Component } from 'react'
import './App.css'
import Stampcard from './Components/Stampcard'
import { AppInstances } from './utils/buzzoekSdkInstance'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
// import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Util from './utils/Util'
import { ToastsContainer, ToastsStore } from 'react-toasts'
import 'whatwg-fetch'
import { setLanguage, translate } from 'react-internationalization'
const SHARED_DATA_ENDPOINT = '/uuid'
// import SocialButton from './Components/SocialLoginButton'

// const handleSocialLogin = user => {
//   console.log(user)
// }

// const handleSocialLoginFailure = err => {
//   console.error(err)
// }

class App extends Component {
  constructor() {
    super()
    this.state = {
      scheme: {}
    }
  }

  async login() {
    if (process.env.REACT_APP_PROD !== 'true') console.log('### Login')
    const access_token = localStorage.getItem('access_token')
    const refresh_token = localStorage.getItem('refresh_token')
    const storedDeviceUuid = localStorage.getItem('StoredDeviceUuid')

    const buzzoek = await AppInstances.getBuzzoekSdk()

    //if we have a local accessToken etc. then set token and refresh
    if (access_token && refresh_token && storedDeviceUuid) {
      if (process.env.REACT_APP_PROD !== 'true')
        console.log('### Have accessToken and refreshToken')

      fetch(SHARED_DATA_ENDPOINT, {
        method: 'POST',
        body: JSON.stringify({ uuid: storedDeviceUuid })
      }).then(res => {
        console.log('saved to cache')
      }).catch(err => {
        console.log('Err saving to cache', err)
      })

      await buzzoek.setToken({
        access_token: access_token,
        refresh_token: refresh_token
      })
      // this.props.loadingTrue()
      // this.props.setLoadingMsg(translate('signing_in'))
      buzzoek.me.read().then(
        user => {
          if(!window.location.search) {
            var searchParams = new URLSearchParams(window.location.search);
            searchParams.set("cust", storedDeviceUuid);
            window.location.search = searchParams.toString();
          }
          // console.log(user)
          return buzzoek.customers
            .update(user._id, {
              $set: {
                'extras.info': {
                  platform: navigator.platform,
                  useragent: navigator.userAgent,
                  product: navigator.product,
                  localStorage: {
                    StoredDeviceUuid: localStorage.getItem('StoredDeviceUuid'),
                    no_stamps: localStorage.getItem('no_stamps'),
                    current_version: localStorage.getItem('current_version')
                  }
                }
              }
            })
            .then(res => {})
            .catch(err => {})
        },
        error => {
          if (process.env.REACT_APP_PROD !== 'true') console.log(error)
          if (error && error.status && error.status === 401) {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
          }
          // ToastsStore.error(error)
          return
          // this.props.loadingFalse()
          // this.props.setToastMessage('login_error')
          // this.props.showToastMessage()
        }
      )
      this.setScheme()
      //if not, then login using browser unique ID as the username and hash of it as password
    } else {
      let fingerprint
      if(window.location.search) {
        var searchParams = new URLSearchParams(window.location.search);
        fingerprint = searchParams.get("cust");
      } else {
        fingerprint = localStorage.getItem('cust')
      }

      if (process.env.REACT_APP_PROD !== 'true') console.log('### Getting fingerprint')

      let fp_result

      if(!fingerprint) {
        //Get device fingerprint
        // const fp = await FingerprintJS.load({ apiKey: 'vjB33bnQp5D6YKkHVGGN' })
        //TODO Temporarily use Countr api key - Buzzoek one is above
        const fp = await FingerprintJS.load({ apiKey: 'qWrdj7r6z8RlqD9KlAwy' })
        // The FingerprintJS agent is ready.
        // Get a visitor identifier when you'd like to.
        fp_result = await fp.get({extendedResult: true});

        // This is the visitor identifier:
        fingerprint = fp_result.visitorId;

        if (process.env.REACT_APP_PROD !== 'true') console.log('Fingerprint result', fp_result)
      }

      // await Fingerprint2.getV18(options, async (fingerprint, components) => {
      // if (navigator.userAgent.indexOf('Android') < 0) {
      //   //If not Android, add a true uniqueness (date string) - need to find something that can be consistent across reinstalls
      //   fingerprint = fingerprint + '_' + new Date().valueOf()
      // }
      if (process.env.REACT_APP_PROD !== 'true') console.log('### Fingerprint', fingerprint)
      fingerprint = await Util.getDeviceUuid(fingerprint)

      if (process.env.REACT_APP_PROD !== 'true') {
        console.log('### UUID', fingerprint)
        // ToastsStore.info('uuid ' + fingerprint, 5000)
      }

      buzzoek.once('registered', token => {
        localStorage.setItem('token_type', token.token_type)
        localStorage.setItem('access_token', token.access_token)
        localStorage.setItem('refresh_token', token.refresh_token)
        buzzoek.setToken({
          access_token: token.access_token,
          refresh_token: token.refresh_token
        })
        if (process.env.REACT_APP_PROD !== 'true') console.log('### Registered, setting scheme')
        if(!window.location.search) {
          var searchParams = new URLSearchParams(window.location.search);
          searchParams.set("cust", fingerprint);
          localStorage.setItem('cust', fingerprint)
          window.location.search = searchParams.toString();
        }
        this.setScheme()
      })

      return buzzoek.customers
        .create({
          username: fingerprint,
          password: fingerprint,
          confirmation: fingerprint,
          extras: {
            info: {
              platform: navigator.platform,
              useragent: navigator.userAgent,
              product: navigator.product
            },
            fp_result
          }
        })
        .then(
          user => {
            // localStorage.setItem('user', JSON.stringify(user))
            if (process.env.REACT_APP_PROD !== 'true') {
              console.log('### User registered')
              ToastsStore.info(translate('new_customer'), 7000)
            }
            ToastsStore.warning('Welcome! Please ensure you are not browsing in Private / Incognito Mode\nIf in private mode, points may not be correctly saved.\nIf applicable, please deactivate Private Mode and then reload the page.', 10000)
            localStorage.setItem('current_version', process.env.REACT_APP_VERSION)

            return buzzoek.register(fingerprint, fingerprint).then(() => {
              return
            })
          },
          error => {
            // console.log(error)
            if (process.env.REACT_APP_PROD !== 'true') {
              console.log('### User registration error')
              console.log(error)
            }
            if (error.error === 'duplicate_resource') {
              // console.log("DUPLICATE RES",fingerprint)
              // ToastsStore.error('Duplicate resource')
              return buzzoek.register(fingerprint, fingerprint).then(() => {
                return
              })
            }
            ToastsStore.error(translate('check_network_connection'))
            return
          }
        )
      // })
    }
  }
  async setScheme() {
    if (process.env.REACT_APP_PROD !== 'true') console.log('### setScheme')
    //Go to server and get the merchant values
    const org = ((window.location.hostname || '').split('.') || [])[0]
    const buzzoek = await AppInstances.getBuzzoekSdk()
    /*buzzoek.stores
      .read({ org_slug: org, limit: 1 })
      .then(stores => {
        //set store
        this.setState({ store: (stores || [])[0] })*/

    buzzoek.loyaltySchemes
      .search({ text: org })
      .then(schemes => {
        this.setState({ scheme: (schemes || [])[0] })
        //TODO if no scheme found, serve waiting page
        if (!(schemes || [])[0]) {
          ToastsStore.error(translate('no_scheme_found_for').replace('{merchant}', org))
          if (process.env.REACT_APP_PROD !== 'true') console.log('### No scheme found for', org)
          // console.log('NO SCHEME FOUND FOR', org)
        } else if((schemes[0].extras || {}).disabled) {
          //If unpaid / otherwise disabled, show toast message and block card
          ToastsStore.error(translate('scheme_disabled'), 120000)
          this.setState({scheme: null})
        }
      })
      .catch(err => {
        // console.log('FAILED TO READ SCHEME', err)
        if (process.env.REACT_APP_PROD !== 'true') console.log('### Failed to read scheme', err)
        // reset access & refresh token if failed due to authentication
        if (err && err.status && err.status === 401) {
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
        }
        ToastsStore.error(translate('failed_to_read_loyalty_scheme'))
      })

    /*})
      .catch(err => {
        return null
      })*/
  }

  async componentDidMount() {
    const lang = localStorage.getItem('language') || 'en'
    setLanguage(lang)
    await this.login()
  }

  render() {
    return (
      <div className="">
        {/* <div className="topheader">
          <header className="header-container">
            <nav className="navbar-card justify-content-right">
              <div className="navbar-end-top">
                <SocialButton
                  provider="linkedin"
                  appId="YOUR_APP_ID"
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  // className="button-item"
                  className="navbar-item"
                >
                  <img className="logo-profile" src="img/Profile-loyalty.png" alt="About Buzzoek" />
                </SocialButton>
              </div>
            </nav>
          </header>
        </div> */}
        <section className="results--section">
          {/* <div className="container">
                        <h1>Cafe Loyalty</h1>
                    </div> */}
          <div className="results--section__inner">
            {this.state && this.state.scheme && this.state.scheme._id && (
              <Stampcard this_scheme={this.state.scheme} />
            )}
          </div>
        </section>
        <ToastsContainer store={ToastsStore} />
        {/* <hr className="hr-bottom" /> */}
        <div className="bottomfooter">
          <header className="header-container">
            <nav className="navbar-card justify-content-center">
              <div className="navbar-end-bottom">
                <hr className="hr-bottom" />
                {((this.state.scheme || {}).extras || {}).powered_by_string || 'Powered by'}
                <a
                  className="navbar-item"
                  href={
                    ((this.state.scheme || {}).extras || {}).powered_by_url || 'https://www.buzzoek.com'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="logo-profile"
                    src={((this.state.scheme || {}).extras || {}).powered_by_logo || 'img/buzzoek_logo.png'}
                    alt="Buzzoek"
                  />
                </a>
              </div>
            </nav>
          </header>
        </div>
      </div>
    )
  }
}

export default App
